import { Grid } from '@mui/material'
import styled from 'styled-components'
import { TextBody2, TextBody2Bold } from '../Styles/TextCustom'
import { IOrigoAccount } from '../../graphql/hid/getOrigoAccount'
import { themeColors } from '../../const/colors'
import { OrigoEventName } from '../../graphql/hid/getOrigoAccount'
import { Roles } from '../../const/permissions'
import { checkRole } from '../../utils/checkRole'
import { useAppSelector } from '../../redux/store'

export const accountUnderCreation = [
  OrigoEventName.NEW,
  OrigoEventName.USER_CREATED,
]

const HIDUserAccountInfo = ({ account }: { account?: IOrigoAccount }) => {
  const { roles } = useAppSelector(state => state.user)

  if (!account || !checkRole(roles, [Roles.developer])) {
    return null
  }
  const { status, userId } = account

  const data = [
    { label: 'Status konta', value: status },
    { label: 'User ID', value: userId },
  ]

  return (
    <PaperSection>
      {data.map(item => (
        <Row key={item.label}>
          <TextBody2>{`${item.label}: `}</TextBody2>
          <TextBody2Bold>{item.value ? item.value : 'Brak'}</TextBody2Bold>
        </Row>
      ))}
    </PaperSection>
  )
}

export default HIDUserAccountInfo
const Row = styled(Grid)`
  padding: 2px 0;
`
const PaperSection = styled.div`
  background-color: ${themeColors.paleGray};
  border-radius: 16px;
  padding: 20px 16px;
  margin: 16px 0;
`
