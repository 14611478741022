import { useQuery } from '@apollo/client'
import { Typography, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { useAppSelector } from '../../redux/store'
import {
  GET_HID_CONFIG,
  IGetHIDConfigData,
  IGetHIDConfigVars,
} from '../../graphql/hid/getHIDConfig'
import { themeColors } from '../../const/colors'
import {
  TextBody1Medium,
  TextBody1Bold,
} from '../../components/Styles/TextCustom'

const HIDServiceData = () => {
  const { t } = useTranslation(['hid'])
  const { tenantName } = useAppSelector(state => state.user)

  const { data, loading } = useQuery<IGetHIDConfigData, IGetHIDConfigVars>(
    GET_HID_CONFIG,
    {
      fetchPolicy: 'no-cache',
      variables: {
        name: tenantName,
      },
    },
  )

  const productData = [
    {
      label: t('number_of_cards'),
      value: data?.getTenant.hidConfig?.maxNumberOfMobileCards,
    },
    { label: t('used_cards'), value: data?.getTenant.employees.length },
    {
      label: t('active_cards'),
      value: data?.getTenant.employees.filter(({ accounts }) => accounts.length)
        .length,
    },
  ]

  if (loading) {
    return <FullScreenLoader />
  }

  if (!data?.getTenant?.hidConfig) {
    return (
      <TypographyStyled variant="h6">
        {t('no_hid_account_assigned')}
      </TypographyStyled>
    )
  }

  return (
    <>
      <TypographyStyled variant="h6" color={themeColors.primary}>
        {t('admin_menu:mobile_access')}
      </TypographyStyled>
      <Container>
        {productData.map(item => (
          <Row key={item.label}>
            <Text>{`${item.label}: `}</Text>
            <TextBold>{item.value}</TextBold>
          </Row>
        ))}
      </Container>
    </>
  )
}

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
`

const Container = styled.div`
  background-color: ${themeColors.lightBlue};
  border-radius: 20px;
  padding: 16px;
`
const Text = styled(TextBody1Medium)`
  display: inline;
`
const TextBold = styled(TextBody1Bold)`
  display: inline;
`
const Row = styled(Grid)`
  padding: 2px 0;
`
export default HIDServiceData
