const en = {
  generic: {
    error: 'Error',
    ups: 'Oops!',
    understand: 'Understand',
    no_options: 'No options',
    network_error: 'Network error',
    qraphql_error: 'GraphQL error',
    no: 'No',
    maybe_later: 'Maybe later',
    exit_without_saving:
      'Are you sure you want to leave without saving changes?',
    free: 'Free',
    yes: 'Yes',
    active: 'Active',
    inactive: 'Inactive',
    visible: 'Visible',
    invisible: 'Invisible',
    available: 'Available',
    unavailable: 'Unavailable',
    success_message: 'Changes have been saved',
    selected: 'selected',
    no_results: 'No results',
    reservation_created: 'Reservation has been created',
    from: 'From',
    to: 'To',
  },
  btn: {
    log_in: 'Log in',
    go_to_app: 'Go to application',
    create_new_password: 'Create new password',
    save: 'Save',
    confirm: 'Confirm',
    cancel: 'Cancel',
    accept: 'Accept',
    reject: 'Reject',
    add_tenant: 'Add tenant',
    save_tenant: 'Save tenant',
    add_domain: 'Add domain',
    delete_domain: 'Delete domain',
    delete: 'Delete',
    delete_selected: 'Delete selected',
    send_message: 'Send message',
    send_again: 'Send selected again',
    exit: 'Exit',
    add_menu_item: 'Add new dish',
    more_data: 'More Data',
    edit: 'Edit',
    add_dish: 'Add dish',
    edit_dish: 'Edit dish',
    add_subscriptions_parking: 'Add subscription',
    search: 'Search',
    filtr_clear: 'Clear filter',
    clear_filters: 'Clear filters',
    expand: 'Expand',
    collapse: 'Collapse',
    backToUser: 'go back to User Details',
    add_survey: 'Add Survey',
    show_regulation: 'Show regulations',
    hide_regulation: 'Hide regulation',
    add_comment: 'Add comment',
    submit_range: 'Submit date range',
    save_changes: 'Save changes',
    logout: 'Logout',
    download_csv: 'Download CSV',
  },
  form: {
    password: 'Password',
    new_password: 'New password',
    email_login: 'Email or Login',
    field_required: 'This field is required',
    select_tenant: 'Select a tenant...',
    tenant_required: 'You must select a tenant to accept',
    no_tenants: 'No tenants',
    comment: 'Optional comments for the user',
    company_name: 'Company name',
    domain_name: 'Email domain name',
    login: 'Login',
    contact_email: 'Contact Email',
    work_email: 'Work Email',
    first_name: 'First Name',
    last_name: 'Last Name',
    search: 'Search',
    send_again: 'Resend selected',
    message: 'Write a reply',
    form_error: 'Fill in required fields',
    log_comment: 'Comment',
    valid_nip: 'Enter valid NIP',
    card_number: 'Card number',
    valid_car_plate: 'Enter correct license plate number',
    valid_card_number: 'Enter a valid card number in the format: 999.99999',
  },
  days: {
    Monday: 'Mo',
    Tuesday: 'Tu',
    Wednesday: 'We',
    Thursday: 'Th',
    Friday: 'Fr',
    Saturday: 'Sa',
    Sunday: 'Su',
  },
  admin_menu: {
    garden: 'Garden',
    garden_tickets: 'Garden tickets',
    gastro: 'Restaurant',
    gastro_menu: 'Menu',
    orders: 'Orders',
    orders_history: 'Order history',
    parking_areas: 'Parking',
    mobile_access: 'Mobile access card',
    parking_sold_subscription_list: 'Sold subscriptions list',
    payment_history: 'Payment history',
    parking_subscription_product_list: 'Subscriptions products list',
    parking_sold_one_time_tickets: 'Sold one time parking ticket list',
    parking_list: 'Parking list',
    parking_places: 'Parking place statuses',
    parking_place_reservations: 'Parking place reservations',
    users: 'Users',
    tenant_issues: 'Tenant status requests',
    users_list: 'Users list',
    unconfirmed_emails: 'Unconfirmed emails',
    companies_list: 'Companies',
    messages: 'Messages',
    minimize_menu: 'Minimize Menu',
    surveys: 'Surveys',
    surveys_list: 'Surveys List',
    surveys_results_list: 'Surveys Results ',
    blocking_olivka_products: 'Blocking Olivka products',
    bike_lockers: 'Bike lockers',
    lockers_list: 'Locker list',
    product_list: 'Product list',
    group_reservation_list: 'Group reservation list',
    reservation_list: 'Reservation list',
    locker_statistics: 'Statistics',
    parking_occupancy_report: 'Parking occupancy report',
    getresponse_sync: 'Synchronization',
    email_list: 'User list',
    integrations: 'Integrations',
    reservations: 'Company parking reservations',
    data: 'Product data',
    occupancy_report: 'Occupancy report',
    parking_pool_data: 'Parking pool data',
    hid_data: 'Mobile access product data',
  },
  login_screen: {
    my: 'My',
    remind_password: "I don't remember my password",
  },
  email_verification_page: {
    success: 'Your account is now active',
    tip: 'You can now log into the application',
    new_email_title: 'Your new email address has been confirmed',
    new_email: 'You can now log in with your new email',
  },
  recover_password_page: {
    title: 'Create a new password for your account',
    success:
      'Password was changed correctly, you can now log into the application.',
  },
  tenant_request_details: {
    title: 'Request for resident status',
    full_name: 'Name:',
    message: 'Message:',
    login: 'Login:',
    contact_email: 'Contact email:',
    company_email: 'Company email:',
    accepted: 'Accepted',
    rejected: 'Rejected',
    reply: 'Reply:',
    close_at: 'Closure date:',
    resolved_by: 'Verified by:',
  },
  user_message_details: {
    title: 'Message details',
    from: 'From:',
    email: 'Email:',
    company: 'Company:',
    date: 'Date:',
    message: 'Message:',
    success: 'Message was sent correctly',
    reply: 'Reply:',
    closeAt: 'Closure date:',
    resolved_by: 'Replied by:',
    delete_account: 'Account deletion request',
    os: 'System:',
    app: 'App version:',
    api: 'API version:',
    backend: 'Backend version:',
  },
  tenant: {
    add_new_tenant: 'Add new tenant',
    edit_tenant: 'Edit tenant',
    tenant_domains: 'Tenant domains:',
    add_domain: 'Add domain:',
    confirm_title: 'Are you sure you want to edit this tenant?',
    add_tenant_success: 'New tenant was added',
    add_domain_success: 'Domain was created',
  },
  email_verification_requests: {
    send_again: 'Send again',
    success: 'Email with verification code has been sent again',
    delete_request: 'Are you sure you want to delete this verification code?',
    delete_selected_requests:
      'Are you sure you want to delete selected verification codes?',
  },
  error: {
    no_app: 'Application not found.',
  },
  code: {
    100: 'OK',
    101: 'Email verified',
    102: 'Profile changed',
    103: 'Password changed',
    104: 'Tenant status updated',
    105: 'Email confirmed',
    106: 'User logged in',
    107: 'User registered',
    108: 'Change email address request accepted. Follow instructions from mail message.',
    109: 'Tenant status accepted',
    110: 'Tenant status rejected',
    111: 'Acceptance status changed',
    200: 'Ticket ordered',
    201: 'Ticket found',
    202: 'Ticket cancelled',
    300: 'Issue submitted',
    301: 'Issue resolved',
    400: 'Click verification link sent by email',
    998: 'Reserved - do not use',

    999: 'Error 999 occurred, please try again later',
    1000: 'Bad email address',
    1001: 'Error 1001 has occurred, please try again later',
    1002: 'Bad login or password',
    1003: 'Email not verified',
    1004: 'Error 1004 has occurred, please try again later',
    1005: 'Cannot verify email',
    1006: 'Invalid token, please login again',
    1007: 'Error 1007 occurred, please try again later',
    1008: 'Error 1008 occurred, please try again later',
    1009: 'Cannot add user',
    1010: 'Cannot verify user',
    1011: 'Date in wrong format',
    1012: 'Internal error - cannot get user info',
    1013: 'Cannot confirm tenant status',
    1014: 'Cannot update user',
    1015: 'Cannot update tenant status',
    1016: 'Cannot confirm email',
    1017: 'Login already taken',
    1018: 'Email address already registered',
    1019: 'Missing parameter: email',
    1020: 'Missing parameter: verification_code',
    1021: 'Missing parameter: password',
    1022: 'Cannot change password',
    1023: 'Unsupported email type',
    1024: 'Internal error - cannot parse template',
    1025: 'Invalid/expired verification code',
    1026: 'Bad parameter - action',
    1027: 'Unfortunately, the e-mail address provided was not found on our resident list - select option below.',
    1028: 'Insufficient privileges',
    1029: 'Cannot assign role',
    1030: 'Cannot unassign role',
    1031: 'Cannot add tenant',
    1032: 'Cannot modify tenant',
    1033: 'Cannot delete tenant',
    1034: 'Cannot create domain',
    1035: 'Cannot modify domain',
    1036: 'Cannot delete domain',
    1037: 'Cannot assign domain',
    1038: 'Cannot unassign domain',
    1039: 'Bad service name',
    1040: 'Cannot send email',
    1041: "We don't have such an account in our database",
    1042: 'Login too short',
    1043: 'Bad character in login',
    1044: 'Cannot resend verification code - email not known or code expired',
    1100: 'Required acceptances not provided',
    2000: 'No active ticket',
    2001: 'No active ticket',
    2002: 'Ticket server internal error',
    2003: 'Cannot buy ticket for past date',
    2004: 'Already have active ticket',
    2005: 'Internal error - unknown parameter for the ticket server',
    2006: 'Ticket server internal error',
    2007: 'You were close. There is no free slot for the date selected by you.',
    2008: 'Cannot order ticket',
    2009: 'Date out of range',
    2010: 'Oops ... You cannot book your ticket more than 31 days in advance.',
    2011: 'Ticket already used',
    3000: 'Unsupported issue type',
    3001: 'Unable to submit your request, please try again later',
    3002: 'Cannot get issue',
  },
  user_details: {
    title: 'User details',
    confirm_modal_title: 'Confirm changes',
    firstName: 'First Name',
    lastName: 'Last Name',
    company: 'Company',
    user_roles: 'User roles',
    no_roles: 'No user roles',
    choose_role: 'Choose user roles',
    unconfirmed_user: '* Unconfirmed user',
    tenant_change_success: 'Tenant was assigned',
    tenant_deleted_success: 'Assigned tenant has been deleted',
    add_role: 'Role was assigned',
    delete_role: 'Role was unassigned',
    assigned_restaurants: 'Assigned restaurants',
    no_restaurants: 'No restaurants',
    choose_restaurant: 'Choose restaurant',
    add_restaurant: 'Restaurant was assigned',
    delete_restaurant: 'Restaurant was unassigned',
    switch_lock_enable: 'User unlocked',
    switch_lock_disabled: 'User blocked',
    lock: 'User lock',
    lock_success: 'User has been blocked',
    unlock_success: 'User has been unblocked',
    parking_no_plate: 'No registration numbers',
    parking_placeHolder: 'Cars plate ',
    user_parking: 'User cars plates ',
    acceptances: 'User acceptances:',
    global_searcher: 'Search more information about user:',
    add_feature: 'Feature was assigned',
    delete_feature: 'Feature was unassigned',
    user_features: 'User features',
    no_features: 'No features',
    choose_feature: 'Choose feature',
    shared_parking_enabled: 'Feature enabled',
    shared_parking_disabled: 'Feature disabled',
    share_parking_feature: 'Sharing parking places',
    access_card: 'Access card',
    access_card_change_success: 'Access card assigned',
    car_plate: 'Car plate',
  },
  user_search: {
    garden_tickets: 'Show tickets to garden',
    user_acceptance_history: 'Show users consent history',
    parking_subcriptions: 'Show parking subscriptions / tickets',
    user_messages_new: 'Show new messages from the user',
    user_messages_closed: 'Show closed messages from user',
    no_questions: 'No inquiries',
    get_query: 'Choose your query',
  },

  table_component: {
    download_csv: 'Download CSV',
  },
  columns: {
    full_name: 'Full name',
    login: 'Login',
    main_email: 'Main email',
    work_email: 'Work email',
    card_number: 'Card number',
    unconfirmed_email: 'Unconfirmed email',
    created_at: 'Send date',
    company: 'Company',
    tenant: 'Tenant',
    domains: 'Email domains',
    roles: 'Roles',
    message: 'Message content',
    message_date: 'Message date',
    close_date: 'Closure date',
    status: 'Status',
    visit_date: 'Visit date',
    qr_code: 'QR Code',
    reply: 'Reply',
    creation_date: 'Creation date',
    dish_name: 'Dish name',
    price: 'Price',
    availability: 'Available',
    visible: 'Visible',
    diet_type: 'Diet type',
    category: 'Category',
    last_mobile_app: 'Last logged from mobile app',
    last_seen: 'Last login date',
    api_version: 'API version',
    car_plate: 'Car Plates',
    features: 'Features',
    ticket_type: 'Ticket type',
    order_id: 'Order Id',
    payment_status: 'Payment status',
    parking: 'Parking',
    valid_to: 'Valid to',
    valid_from: 'Valid from',
    reservation_status: 'Reservation status',
    subscription_status: 'Subscription status',
    feature_enabled: 'Feature enabled',
    place: 'Place',
    user_assigned: 'Assigned to service',
    profile: 'Profile',
  },

  non_data: {
    non_data: '[non Data]',
  },
  user_details_table: {
    createdAt: 'Date of change',
    service: 'What kind of service',
    title: 'Consent title',
    required: 'Is it required?',
    current: 'Current user choice',
    content: 'The content of the consent',
    buttonBack: 'Close and back to User List',
    topic: 'User consent history:',
  },
  user_acceptances: {
    required: 'Is consent required?:',
    selected: 'Has the user selected consent?:',
    service: 'Which service is the consent for:',
    current: 'Is the consent / regulations currently in force:',
    createdAt: 'First Decision Date:',
    modifiedAt: 'Date of the last decision:',
  },
  bool: {
    yes: 'Yes',
    no: 'No',
  },
  payment_history: {
    details_title: 'Payment details',
    payment_date: 'Payment date',
    ticket_id: 'Ticket id',
    ticket_type: 'Ticket type',
    ticket_start: 'Ticket from',
    ticket_stop: 'Ticket to',
    product_name: 'Name of product',
    seller_company: 'Seller',
    seller_nip: 'Seller NIP',
    invoice: 'Invoice',
    invoice_data: 'Invoice data',
    parking_name: 'Parking name',
    fv: 'Faktura Vat',
    payment_id: 'Payment ID',
    account_firstname: 'First Name',
    account_lastname: 'Last Name',
    email: 'Email',
    nip: 'Nip',
    name: 'Company name',
    zipcode: 'Zipcode',
    street: 'Street',
    city: 'City',
    country: 'Country',
    car_plate_1: 'Car plate',
    car_plate_2: 'Car plate',
    access_card_id: 'OBC card ID',
    tenant_name: 'Tenant',
    payment_value_gross: 'Payment value gross',
    payment_history: 'Payment history',
    payment_status: 'Payment status',
    subscription: 'Subscription',
    oneTimeTicket: 'One time ticket',
    ticket_number: 'Ticket number',
    dates: 'Dates',
    car_plates: 'Car plates',
    price: 'Price',
    created_at: 'Created at',
    payment: 'Payment',
    lease_from: 'Lease from',
    lease_to: 'Lease to',
    building: 'Building',
    locker_number: 'Locker number',
    locker_payment_history: 'Locker payment history',
    parking_payment_history: 'Parking payment history',
  },
  parking_subscriptions_products_list: {
    parking_id: 'Parking ID',
    parking_name: 'Parking Name',
    product_duration: 'Product Duration',
    product_name: 'Product Name',
    product_id: 'Product ID',
    product_price: 'Product Price',
    product_valid_from: 'Product available from',
    product_valid_to: 'Product available to',
    active: 'Active',
    max_spaces_to_sell: 'Spaces to sell',
    seller_company_name: 'Seller company name',
    product_regulations: 'Product regulations',
    subscriptions_list: 'Subscriptions list',
  },
  parking_subscription_form: {
    add_title: 'Add Subscriptions',
    edit_title: 'Edit Subscriptions',
    name_and_price: 'Name and price',
    valid_from: 'Product available from:',
    duration: 'Subscription duration:',
    price: 'Subscription price:',
    price_label: 'Price',
    limit: 'Number of parking spaces for sale:',
    max_advance:
      'Maximum number of days in which a ticket can be purchased before use:',
    min_advance:
      'Minimum number of days in which a ticket can be purchased before use:',
    after_end:
      'How many days after the ticket expires, the parking space will be reserved:',
    valid_to: 'Product available until:',
    active: 'Is the product active:',
    select_parking: 'Select Parking',
    select_parking_no: 'No parking lots',
    rules: 'Regulations',
    select_no_rules: 'No Regulations',
    select_rules: 'Choose a regulations',
    company: 'A company that sells a subscription',
    select_company: 'Choose a company',
    select_company_no: 'No companies',
    add: 'Add',
    active_yes: 'Yes, the product is active',
    active_no: 'No, the product is not active',
    date: 'Choose a date',
    subscription: 'Subscription Name:',
    subscription_pl: 'Polish subscription name',
    subscription_en: 'English subscription name',
    days: 'Days',
    places: 'Lot',
    show_regulation: 'Show regulation content',
    min_advance_others:
      'Minimum number of working days in which a ticket can be purchased before use for buildings other than mine',
    sort: 'Sort',
    amano_product_no: 'Amano product number',
    value: 'Value',
    number: 'Number',
  },
  parking_list: {
    parking_list: 'Parking list',
    edition_parking: 'Edit Parking',
    name: 'Parking Name',
    limit_place: 'Parking space limit',
    places: 'Places',
    building: 'List of assigned buildings to the parking lot:',
    building_list: 'Expand the list of buildings',
    building_list_no: 'No list of buildings',
    parking_places: 'List of dedicated parking spaces',
    parking_places_select: 'Expand the list of targeted locations',
    parking_places_select_no: 'There is no list of specific places',
    remarks: 'Remarks',
    is_dedicate_places: 'There is dedicate place?',
    dedicate_places_number: 'Number of dedicated places',
    parking_building: 'Buildings assigned to the car park',
    company: 'Car park operator',
    company_email: 'Email to car park operator',
    go_to_places: 'Go to parking places',
    date: 'Date',
    capacity: 'Capacity',
    sold: 'Sold',
    used: 'Used',
    parking_occupancy_report: 'Parking occupancy report',
  },
  sold_parking_items: {
    valid_subscriptions_list: 'Valid subscriptions list',
    sold_one_time_tickets: 'Sold one time parking ticket list',
    valid_from: 'Valid from',
    valid_to: 'Valid to',
    product_name: 'Product name',
    product_id: 'Product Id',
    car_plates: 'Car plates',
    car_plate: 'Car plate',
    status: 'Subscription status',
    ticket_status: 'Status biletu',
    purchase_date: 'Purchase date',
    payment_amount: 'Payment amount',
    payment_status: 'Payment status',
    payment_id: 'OBC payment ID',
    payment_external_id: 'PayU payment ID',
    payment_created_at: 'Payment date',
    logs: 'Logs:',
    no_logs: 'No logs',
    log_message: 'Message',
    created_at: 'Created at',
    valid_to_info: 'with valid to date equal or greater than:',
    details_title: 'Subscription details',
    user: 'Buyer data',
    dates: 'Dates',
    related_tickets_and_payments: 'Related tickets and payments:',
    ticket: 'Ticket',
    place: 'Place',
    parameters: 'Parameters:',
    name: 'name',
    type: 'type',
    value: 'value',
    sync_with_amano: 'Sync with Amano',
    cancel_subscription: 'Cancel subscripion',
    sync_date_and_time: 'Sync date and time',
    subscription_cancelled: 'Subscription cancelled',
    cancel_subscription_info:
      'Are you sure you want to cancel this subscription?',
    ticket_number: 'Ticket number',
    ticket_details: 'Ticket details',
    comment: 'Comment',
    action_comment: 'Cancellation/resynchronization comment',
    reschedule_success: 'Subscription have been synchronized',
    sale_id: 'Sale ID',
    tenant: 'Tenant',
    payment_value: 'Price',
  },
  parking_subscription_status: {
    active: 'Active',
    cancelled: 'Cancelled',
    error: 'Error',
    new: 'New',
    past: 'Past',
    pending: 'Pending',
  },
  filter: {
    title: 'Advanced filtering by column',
    choose_column: 'Choose a column',
    choose_column_no: 'No column in table',
    value: 'Value',
  },
  feature: {
    gastro_mobile: 'Restaurant mobile',
    gastro_admin: 'Restaurant admin',
    parking_mobile: 'Parking mobile',
    parking_admin: 'Parking admin',
    olivia_news: 'Olivia news',
    survey_with_upsert: 'Survey with upsert',
  },
  logs: {
    logs: 'Logs:',
    no_logs: 'No logs',
    parameters: 'Parameters:',
    name: 'name',
    type: 'type',
    value: 'value',
  },
  parking_places: {
    parking_place_statuses: 'Parking places statuses',
    parking_place_reservations: 'Parking places reservations',
    level: 'Level',
    place: 'Place',
    shared: 'Shared',
    lease_term: 'Lease term',
    company: 'Company',
    tenant: 'Tenant',
    from: 'From',
    to: 'To',
    show_place_numbers: 'Show place numbers',
    available: 'Available',
    unavailable: 'Unavailable',
    reserved: 'Reserved',
    occupied: 'Occupied',
    to_rent: 'To rent',
    rented: 'Rented',
    yes: 'Yes',
    no: 'No',
    hide_numbers: 'Hide place numbers',
    show_numbers: 'Show place numbers',
    unlimited: 'unlimited',
    change_places_status: 'Change parking spaces status',
    change_status: 'Change status',
    new_status: 'New status',
    comment: 'Comment',
    change_status_button_hint: 'Select status and places from the list',
  },
  reservation_status: {
    ready_to_rent: 'For release',
    rented_overdue: 'To pass',
    rented: 'Rented',
    available: 'Available',
    broken: 'Damaged',
    reserved: 'Reserved',
    prereserved: 'Pre-reserved',
    error: 'Error',
    cancelled: 'Cancelled',
    finished: 'Finished',
  },
  payment_status: {
    completed: 'Completed',
    confirmed: 'Confirmed',
    error: 'Error',
    failed: 'Failed',
    new: 'New',
    processing: 'Processing',
    received: 'Received',
    cancelled: 'Cancelled',
  },
  parking_ticket_status: {
    new: 'New',
    reserved: 'Reserved',
    processing: 'Processing',
    paid: 'Paid',
    cancelled: 'Cancelled',
    unavailable: 'Unavailable',
    error: 'Error',
  },
  parking_pool_data: {
    amano_product_id: 'Amano product id',
    places_amount: 'Places amount',
    max_simultaneous_users: 'Max simultaneous users',
    max_continuous_days: 'Max continuous days',
    max_advanced_days: 'Max advanced days',
    max_simultaneous_reservations: 'Max simultaneous reservations',
    no_product: 'No product assigned',
    no_valid:
      'No assigned parking spaces. Report the problem to the parking administrator',
  },
  parking_pool_occupancy_report: {
    date: 'Date',
    capacity: 'Active user count',
    sold: 'Reserved',
    used: 'Used',
    max_user_count: 'Max user count',
    max_places_count: 'Places count by contract',
    places_count: 'Places count',
  },
  hid: {
    hid_account: 'Mobile access card Account',
    mobile_access_card: 'Mobile access card',
    create_hid_account: 'Create HID account',
    delete_hid_account: 'Delete HID account',
    true: 'Yes',
    false: 'No',
    active: 'Active',
    inactive: 'Inactive',
    account_created: 'Account created. No cards assigned.',
    card_number: 'Card number',
    privileges_requested: 'Privileges requested',
    privileges_granted: 'Privileges granted',
    access_granted: 'Access granted',
    success_message: 'The account has been created',
    delete_HID_account: 'Delete HID account',
    delete_HID_card: 'Delete HID card',
    delete_account_confirmation:
      'Are you sure you want to delete the mobile access account of the user ',
    delete_card_confirmation:
      'Are you sure you want to remove the card with the number ',
    no_hid_account_assigned: 'No HID account assigned',
    hid_account_deleted: 'HID account deleted',
    account_deletion_in_progress: 'Account deletion in progress...',
    account_under_creation: 'Account under creation',
    card_data: 'Card details:',
    card_under_creation: 'Card under creation...',
    card_deletion_in_progress: 'Card removal in progress...',
    device: 'Device',
    card_deleted: 'Card deleted',
    create_card: 'Create card',
    card_settings: 'Card settings',
    with_parking_access:
      'Check if the user already has active access to the parking',
    max_number_of_mobile_cards: 'Maximum number of cards',
    used_cards: 'Used cards',
    deletion_requirement:
      'To remove the card, the device ID is required. Please refresh or wait for the user to open the application.',
    no_card_created: 'Card creation failed',
    no_device_warning: `The card is awaiting installation on the user's phone. To install it, the user must open MyOlivia app, click on the 'Access Card' module, and follow the instructions.`,
    comment: 'Additional remarks for the submission',
    add_attachment: 'Add attachment to request',
    deletion_comment: 'Optional comment for card removal',
    mysolvy_task_link: 'MySolvy order link',
    number_of_cards: 'Number of cards',
    active_cards: 'Active cards',
    mobile_card_activity: 'Mobile card activity',
    card_status: 'Card status',
    parking_access: 'Parking access',
  },
  parking_pool: {
    assign_user: 'Assign a user to a service',
    assign_user_to_profile: 'Assign a user to a profile',
    assigned_user: 'User assigned',
    unassigned_user: 'Unassigned user',
    no_car_plate_assigned_info: 'To assign car plates add an access card',
    no_card_assigned_info: `To activate user's ability to book parking add access card and car plate`,
    edit_car_plates: 'Edit car plates',
    active_subscription_warning:
      'The user has an active subscription. Are you sure you want to unassign this user from the service?',
    unassign_user: 'Unassign',
    assigned_users: 'Assigned to service',
    no_users: 'No users',
    assign_profile_info:
      'To select a profile, you need to assign a user to the service first..',
    profile: 'Service profile',
    choose_profile: 'Choose profile',
    no_profile: 'No profile',
    assign_error: 'User assignment error occurred',
    unassign_error: 'User unassignment error occurred',
    assign_profile_error: 'User profile assignment occurred',
    unassign_profile_error: 'User profile unassignment error occurred',
  },
}

export default en
